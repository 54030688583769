import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./i18n/en.json";
import tw from "./i18n/zh-tw.json";
import fr from "./i18n/fr.json";
import ko from "./i18n/ko.json";

const resources = {
  en: {
    translation: en,
  },
  "zh-TW": {
    translation: tw,
  },
  fr: {
    translation: fr,
  },
  ko: {
    translation: ko,
  },
};

const browserLng = window.navigator.language;

i18n.use(initReactI18next).init({
  resources,
  lng: browserLng, // language to use (overrides language detection)
  fallbackLng: "en", // language to use if translations in user language are not available.
  debug: false, // set true to help finding issues with loading not working.

  // interpolation: {
  //     escapeValue: false, // not needed for react as it escapes by default
  // }
});

export default i18n;
