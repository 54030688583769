import { PROJECT_INFO } from "redux/types/projectInfo.types";

let PROJECT_INFO_RES = {
  projectInfo: "",
};

const projectInfoReducer = ({ type, payload } = {}, state = PROJECT_INFO_RES) => {
  switch (type) {
    case PROJECT_INFO:
      return {
        ...state,
        projectInfo: payload,
      };
    default:
      return state;
  }
};

export default projectInfoReducer;
