import React from "react";

const useModal = () => {
  const [modalSetting, setModalSetting] = React.useState({
    show: false,
    type: "",
    title: "",
    desc: "",
    notice: "",
    handleConfirm: () => {},
  });

  return {
    modalSetting,
    setModalSetting,
  };
};

export default useModal;
