import styled from "styled-components";

export const StyledSideBarNav = styled.div`
  position: fixed;
  padding: 18px 0 0 32px;
  top: 140px;
  left: 0px;
  width: 98px;
  height: 480px;
  background: rgba(0, 0, 0, 0.87);
  border-radius: 0px 20px 20px 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 99;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    margin: 40px 0px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .closeMobileBar {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
      position: absolute;
      width: 100%;
      text-align: center;
      top: -5px;
      left: 0;
      font-size: 25px;
    }
    svg {
      color: rgba(255, 255, 255, 0.87);
    }
  }
  .openMobileBar {
    width: 100px;
    height: 36px;
    background: rgba(0, 0, 0, 0.87);
    border-radius: 24px 24px 0 0;
    text-align: center;
    line-height: 36px;
    position: fixed;
    display: none;
    left: 50%;
    margin-left: -50px;
    bottom: 0;
    @media screen and (max-width: 768px) {
      display: block;
    }
    svg {
      color: rgba(255, 255, 255, 0.87);
      font-size: 25px;
    }
  }
  .modelImage {
    width: 48px;
    height: 48px;
    // border: 1px solid #FFFFFF;
    border-radius: 4px;
    margin-bottom: 15px;
    cursor: pointer;
    object-fit: cover;
    object-position: center center;
    opacity: ${(props) => (props.loadingStatus ? "0.3" : "1")};
    cursor: ${(props) => (props.loadingStatus ? "default" : "pointer")};
  }
  @media screen and (max-width: 1366px) {
    width: 90px;
    .modelImage {
      width: 40px;
      height: 40px;
    }
  }
  .MobileImageContainer {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0);
      margin: 40px 0px;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  @media screen and (max-width: 768px) {
    white-space: nowrap;
    border-radius: 24px 24px 0 0px;
    top: auto;
    bottom: -94px;
    width: 100%;
    height: 92px;
    left: 0%;
    // transform:translateX(-50%);
    transition: 0.5s bottom;
    padding: 10px 20px;
    .MobileImageContainer {
      overflow-y: hidden;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 2px;
      }
    }
    .modelImage {
      margin-top: 15px;
      margin-right: 20px;
      margin-bottom: 0;
      display: inline-block;
      vertical-align: top;
    }
  }
`;
