import styled from "styled-components";

//images
import namIcon from "./images/namIcon.svg";
import pauseIcon from "./images/pauseIcon.svg";
import playIcon from "./images/playIcon.svg";
import closeIcon from "./images/closeIcon.svg";

export const StyledModelViewer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: ${(props) => (props.hiddenModel ? "none" : "block")};
  .justForArLoading {
    width: 100%;
    height: 100%;
    background: #1f1f1f;
    position: fixed;
    top: 0;
    left: 0;
    /* 不能比 MobilePopup 層級高 */
    z-index: 999999;
  }
  .openMobileInner {
    width: 100vw;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999999;
    background-color: rgba(0, 0, 0, 0.87);
    padding: 25px;
    display: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @media screen and (max-width: 768px) {
      display: block;
    }
    .closeBtn {
      color: #fff;
      font-size: 30px;
      right: 20px;
      top: 20px;
      position: absolute;
    }
    .title {
      color: #fff;
      font-size: 16px;
      padding-top: 50px;
      line-height: 24px;
    }
    .word {
      padding-top: 20px;
      color: #fff;
      font-size: 16px;
      line-height: 24px;
    }
    .mobileImg {
      margin-top: 20px;
      width: 100%;
    }
    .mobileLink {
      padding-top: 20px;
      color: #fff;
      font-size: 14px;
      display: block;
      svg {
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }
  .loadingProgressContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    /* background: rgba(255, 255, 255, 0.75); */
    z-index: ${(props) => props.theme.zIndex.LayerNine};
    backdrop-filter: blur(40px);
    .progressBarPanel {
      margin-bottom: 24px;
      width: 300px;
      height: 8px;
    }

    .progress {
      height: 8px;
      background: #e0e0e0 !important;
    }

    .progress-bar {
      background: linear-gradient(79.78deg, #ed0973 0%, #ff744e 100%) !important;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .progressText {
      color: #333333;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  #myViewer {
    width: 100%;
    height: 100%;
    position: relative;
    .hotspot {
      z-index: ${(props) => props.theme.zIndex.LayerNine};
      position: relative;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: #e60363d9;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      transition: all 0.25s ease-in-out;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 0px 6px rgba(230, 3, 99, 0.4);
      }
    }

    .infoCard {
      padding: 10px;
      z-index: ${(props) => props.theme.zIndex.LayerTen};
      position: absolute;
      display: none;
      width: 200px;
      height: auto;
      max-height: 312px;
      background: #000;
      border-radius: 8px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0);
        margin: 10px;
      }
      &::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #c4c4c4;
      }
      @media screen and (max-width: 768px) {
        height: 170px;
        overflow: hidden;
        border-radius: 10px;
        padding: 20px;
      }
      @media screen and (max-width: 414px) {
        height: ${(props) => (props.decOver36 ? "170px" : "auto")};
      }
      .more {
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
          width: 100%;
          color: #fff;
          font-size: 12px;
          text-align: center;
          position: absolute;
          padding-top: 5px;
          padding-bottom: 10px;
          bottom: 0;
          left: 0;
          background: #000;
        }
        @media screen and (max-width: 414px) {
          padding: 10px;
          text-overflow: ellipsis;
          display: ${(props) => (props.fullCard || props.decOver36 ? "block" : "none")};
        }
        svg {
          position: relative;
          top: -2px;
          font-size: 20px;
        }
      }
    }
    .infoCard.full {
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: fixed;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
        max-height: none;
        padding: 40px;
        border-radius: 0;
        .closeIcon {
          display: block;
        }
      }
      .more {
        display: none;
      }
    }

    .section1 {
      right: 30px;
      bottom: 0px;
      @media screen and (max-width: 768px) {
        right: -90px;
        bottom: 10px;
      }
      @media screen and (max-width: 768px) {
        right: -90px;
        bottom: 40px;
      }
    }

    .section2 {
      left: 30px;
      top: 0px;
      @media screen and (max-width: 768px) {
        left: -90px;
        bottom: 123px;
      }
      @media screen and (max-width: 599px) {
        left: -90px;
        bottom: 30px;
      }
    }

    .section3 {
      right: 30px;
      top: 0px;
      @media screen and (max-width: 768px) {
        right: -90px;
        bottom: 30px;
      }
      @media screen and (max-width: 599px) {
        right: -90px;
        bottom: 30px;
      }
    }

    .section4 {
      left: 30px;
      bottom: 0px;
      @media screen and (max-width: 768px) {
        left: -90px;
        bottom: 10px;
      }
      @media screen and (max-width: 768px) {
        left: -90px;
        bottom: 40px;
      }
    }

    .xSection1 {
      right: 30px;
      bottom: 0px;
      @media screen and (max-width: 768px) {
        right: -90px;
        bottom: 10px;
      }
      @media screen and (max-width: 768px) {
        right: -90px;
        bottom: 40px;
      }
    }

    .xSection2 {
      left: 30px;
      bottom: 0px;
      @media screen and (max-width: 768px) {
        left: -90px;
        bottom: 10px;
      }
      @media screen and (max-width: 768px) {
        left: -90px;
        bottom: 40px;
      }
    }

    .show {
      display: block;
    }

    .title {
      text-align: left;
      margin-bottom: ${(props) => (props.onlyTitle ? "0px" : "8px")};
      width: 100%;
      min-height: 20px;
      height: auto;
      font-size: 14px;
    }

    .content {
      text-align: left;
      margin-bottom: ${(props) => (props.onlyTitleDesc ? "0px" : "8px")};
      width: 100%;
      height: auto;
      max-height: 80px;
      font-size: 14px;
      white-space: normal;
      word-break: break-word !important;
      @media screen and (min-width: 414px) {
        overflow: auto;
      }
      @media screen and (max-width: 414px) {
        margin-bottom: ${(props) => (props.onlyTitleDesc ? "0px" : "20px")};
        height: auto;
        max-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0);
      }
      &::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #c4c4c4;
      }
    }

    .image {
      margin-bottom: ${(props) => (props.onlyTitleImage ? "0px" : "8px")};
      width: 100%;
      height: 100px;
      border-radius: 4px;
      background: #1b1b1b;
      @media screen and (max-width: 414px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .urlPanel {
      text-align: left;
      width: 100%;
      height: 22px;
      font-size: 12px;
      text-decoration: none;

      &:hover {
        text-decoration: underline #fff;
      }

      .urlIcon {
        width: 20px;
        height: 20px;

        path {
          fill: white;
        }
      }

      .url {
        top: 50%;
        flex: 1;
        width: calc(100% - 20px);
        height: 22px;
        color: #fff;

        & > span {
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .animation {
    &_conainer {
      width: 100%;
      padding: 0 48px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.3s ease all;
      z-index: 100;
      margin-bottom: 20px;
      @media screen and (max-width: 768px) {
        padding: 0 16px;
        height: 80px;
        bottom: ${(props) => (props.showBar ? "80px" : "44px")};
        bottom: ${(props) => props.showBar && props.showAnimateHidden && "44px"};
      }
    }
    &_bar {
      width: 100%;
      height: 32px;
      z-index: 112;
      @media screen and (max-width: 768px) {
        width: calc(100% - 32px);
        height: 16px;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
      &_tooltip {
        word-break: break-all;
        word-wrap: break-word;
        min-width: 80px;
        text-align: center;
        left: 360px;
        top: -50px;
        border-radius: 4px;
        position: absolute;
        background: rgba(0, 0, 0, 0.87);
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #ffffff;
        padding: 10px;
        &:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          left: 50%;
          top: 30px;
          transform: translateX(-50%) rotate(-90deg);
          border-style: solid;
          border-width: 15px 12px 15px 0;
          border-color: transparent #000000de transparent transparent;
        }
      }
      &_duration {
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
          position: absolute;
          top: 41px;
          right: ${(props) => (props.showAnimateHidden ? "" : "3px")};
          left: ${(props) => (props.showAnimateHidden ? "60px" : "")};
          color: ${(props) => (props.showAnimateHidden ? "#fff" : "")};
          transition: 0.5s all ease;
        }
      }
      input[type="range"] {
        cursor: pointer;
        -webkit-appearance: none;
        width: 100%;
        height: 4px;
        background: rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        background-image: linear-gradient(79.78deg, #ed0973 0%, #ff744e 100%);
        // background-size: 70% 100%;
        background-repeat: no-repeat;
      }
      /* Input Thumb */
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px 0 #555;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
      }

      input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
      }
      input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
      }
    }
    &_control {
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        z-index: 1;
        position: absolute;
        top: 30px;
        left: 26px;
      }
      &_icon_box {
        margin-right: 42px;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          margin-right: 10px;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.87);
          border-radius: 8px;
          .icon {
            background-color: #fff;
          }
          @media screen and (max-width: 768px) {
            background: rgba(0, 0, 0, 0);
            .icon {
              background-color: #000;
            }
          }
        }
        .icon {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #000;
          -webkit-mask: ${(props) =>
            props.animatePlayStatus
              ? `url(${pauseIcon}) no-repeat center`
              : `url(${playIcon}) no-repeat center`};
          mask: ${(props) =>
            props.animatePlayStatus
              ? `url(${pauseIcon}) no-repeat center`
              : `url(${playIcon}) no-repeat center`};
        }
      }
      &_chose {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        border-radius: 4px;
        margin-right: 24px;
        border: 1px solid rgba(0, 0, 0, 0);
        background: ${(props) => (props.showAnimateHidden ? "rgba(0, 0, 0, 0.87)" : "")};
        @media screen and (max-width: 768px) {
          margin-right: 0px;
          padding: 5px;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.87);
          border: 1px solid rgba(0, 0, 0, 0.87);
          cursor: pointer;
          .animation_control_chose_name {
            color: #fff;
          }
          .animation_control_chose_icon {
            background-color: #fff;
          }
          @media screen and (max-width: 768px) {
            background: rgba(0, 0, 0, 0);
            border: 1px solid rgba(0, 0, 0, 0);
            .animation_control_chose_name {
              color: #000;
            }
            .animation_control_chose_icon {
              background-color: #000;
            }
          }
        }
        &_icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          background-color: ${(props) => (props.showAnimateHidden ? "#fff" : "#000")};
          -webkit-mask: url(${namIcon}) no-repeat center;
          mask: url(${namIcon}) no-repeat center;
        }
        &_name {
          font-weight: 900;
          font-size: 16px;
          line-height: 24px;
          color: ${(props) => (props.showAnimateHidden ? "#fff" : "#333333")};
          width: 120px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @media screen and (max-width: 768px) {
            width: 86px;
          }
        }
      }
      &_duration {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #666666;
        @media screen and (max-width: 768px) {
          display: none;
          position: absolute;
          top: -45px;
          right: -155px;
        }
      }
    }
    &_hidden_list {
      position: absolute;
      left: 137px;
      bottom: 55px;
      background: rgba(0, 0, 0, 0.87);
      border: 1px solid rgba(255, 255, 255, 0.12);
      border-radius: 4px;
      padding: 15px;
      width: 204px;
      max-height: 215px;
      overflow-y: scroll;
      overflow-x: hidden;
      @media screen and (max-width: 768px) {
        display: none;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0);
        margin: 0px;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: rgba(255, 255, 255, 0.3);
      }
      &_item {
        display: flex;
        align-items: center;
        width: 175px;
        height: 36px;
        cursor: pointer;
        &.currentPlay {
          .name {
            color: rgba(255, 255, 255, 1);
          }
        }
        .check {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        .name {
          flex-grow: 1;
          max-width: 145px;
          color: rgba(255, 255, 255, 0.5);
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:hover {
            color: #fff;
          }
        }
      }
      &_tooltip {
        word-break: break-all;
        word-wrap: break-word;
        width: 130px;
        left: 360px;
        top: 0px;
        border-radius: 4px;
        position: fixed;
        background: rgba(0, 0, 0, 0.87);
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #ffffff;
        padding: 10px;
        visibility: ${(props) => (props.showTootltip ? "visible" : "hidden")};
        transform: translateY(-50%);
        z-index: 999999999;
        &:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
          border-style: solid;
          border-width: 10px 14px 10px 0;
          border-color: transparent #000000de transparent transparent;
        }
      }
    }
    &_hidden_mobile_list {
      position: absolute;
      left: -1px;
      bottom: -230px;
      bottom: ${(props) => props.showBar && "-280px"};
      bottom: ${(props) => props.showBar && props.showAnimateHidden && "-230px"};
      background: #202020;
      width: 101%;
      height: 165px;
      overflow-y: hidden;
      overflow-x: scroll;
      z-index: 111;
      transition: 0.3s all ease;
      transform: ${(props) => props.showAnimateHidden && "translateY(-130px)"};
      @media screen and (min-width: 768px) {
        display: none;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0);
        margin: 0px;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: rgba(255, 255, 255, 0.3);
      }
      &_controller {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px 5px 20px;
        .icon {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          -webkit-mask: ${(props) =>
            props.animatePlayStatus
              ? `url(${pauseIcon}) no-repeat center`
              : `url(${playIcon}) no-repeat center`};
          mask: ${(props) =>
            props.animatePlayStatus
              ? `url(${pauseIcon}) no-repeat center`
              : `url(${playIcon}) no-repeat center`};
        }
        .close {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          -webkit-mask: url(${closeIcon}) no-repeat center;
          mask: url(${closeIcon}) no-repeat center;
        }
      }
      &_itemContainer {
        display: flex;
        width: calc(100% - 30px);
        height: 50px;
        overflow-y: hidden;
        overflow-x: scroll;
        margin: 0px 15px;
        &_item {
          display: flex;
          align-items: center;
          height: 30px;
          cursor: pointer;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 40px;
          color: rgba(255, 255, 255, 0.5);
          padding: 3px 10px;
          margin-right: 16px;
          &.currentPlay {
            background: rgba(255, 255, 255, 0.87);
            border: 1px solid rgba(255, 255, 255, 0.87);
            .name {
              color: #333333;
            }
          }
          .check {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
          .name {
            flex-grow: 1;
            max-width: 105px;
            color: rgba(255, 255, 255, 0.5);
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .choseOpenMode {
    position: absolute;
    bottom: ${(props) => (props.isOpenTag ? "56px" : "106px")};
    right: 48px;
    background: rgba(0, 0, 0, 0.87);
    color: #fff;
    padding: 8px 24px;
    border-radius: 4px;
    cursor: pointer;
    z-index: 99;
    @media screen and (max-width: 768px) {
      padding: 6px;
      right: 16px;
      bottom: ${(props) => (props.isOpenTag ? "56px" : "156px")};
      margin-bottom: ${(props) => props.showBar && "50px"};
      margin-bottom: ${(props) => props.showBar && !props.isOpenTag && "30px"};
      display: ${(props) => (props.showAnimateHidden ? "none" : "block")};
      transition: 0.3s ease all;
      span {
        display: none;
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 35px;
        height: 35px;
        @media screen and (max-width: 768px) {
          width: 23px;
          height: 23px;
        }
      }
    }
  }
`;
