import { MODEL_INDEX } from "redux/types/modelIndex.types";

let USER_MODEL_INDEX = {
  modelIndex: "",
};

const modelIndexReducer = (action, state = USER_MODEL_INDEX) => {
  switch (action.type) {
    case MODEL_INDEX:
      return {
        ...state,
        modelIndex: action.payload,
      };
    default:
      return state;
  }
};

export default modelIndexReducer;
