import en from "i18n/en.json";
import tw from "i18n/zh-tw.json";
import fr from "i18n/fr.json";
import ko from "i18n/ko.json";

import Cookies from "js-cookie";

const iconDelete = "./assets/icons/icon-delete.svg";
const iconAlertFile = "./assets/icons/icon-alert-file.svg";
const iconAlertTag = "./assets/icons/icon-alert-tag.svg";
const iconExit = "./assets/icons/icon-exit.svg";
const iconAlert = "./assets/icons/icon-alert-warn.svg";
const iconCould = "./assets/icons/icon-could.svg";

let lang;

if (Cookies.get("lang") === "en") {
  lang = en;
} else if (Cookies.get("lang") === "fr") {
  lang = fr;
} else if (Cookies.get("lang") === "ko") {
  lang = ko;
} else {
  lang = tw;
}

export const modalConfig = {
  type1: {
    icon: iconDelete,
    type: "delete",
    desc: ["要刪除 ", "", " 標籤嗎?"],
    notice: "標籤刪除後將無法復原。",
  },
  type2: {
    icon: iconAlertFile,
    type: "alert-format",
    desc: "檔案格式錯誤，請重新上傳。",
    notice: "支援: 圖片小於 2MB 的 JPG、PNG 檔。",
  },
  type3: {
    icon: iconAlertFile,
    type: "alert-storage",
    desc: "檔案容量過大，請重新上傳。",
    notice: "支援: jpg、png，圖片大小2M以內。",
  },
  type4: {
    icon: iconAlertTag,
    type: "alert-tag-name",
    desc: "所有標籤須包含標題。",
    notice: "為標籤添加標題 或 刪除無標題標籤。",
  },
  type5: {
    icon: iconExit,
    type: "exit-editor",
    desc: "要離開編輯器嗎?",
    notice: "系統不會自動儲存你所做的變更。",
  },
  type6: {
    icon: iconExit,
    type: "exit-tag",
    desc: "要離開物件標籤嗎?",
    notice: "尚未儲存的標籤將永久遺失。",
  },
  type7: {
    icon: iconDelete,
    type: "file-delete",
    desc: "要刪除已上傳的物件縮圖?",
    notice: "刪除後將無法復原。",
  },
  type8: {
    icon: iconAlertFile,
    type: "alert-format",
    desc: "檔案格式錯誤，請重新上傳。",
    notice: "支援: 比例 2:1 (例:2880x1440) 且小於 2MB 的 HDR、JPG、PNG 檔。  ",
  },
  type9: {
    icon: iconDelete,
    type: "file-delete",
    desc: "要刪除已上傳的HDR?",
    notice: "刪除後將無法復原。",
  },
  type10: {
    icon: iconAlert,
    type: "file-save",
    desc: "您有未儲存的變更，是否儲存並公開專案?",
    notice: "未儲存的變更將遺失且不會顯示於公開頁。",
  },
  type11: {
    icon: iconAlert,
    type: "file-save",
    desc: "您有未儲存的變更，是否儲存並預覽專案?",
    notice: "未儲存的變更將遺失且不會顯示於預覽畫面。",
  },
  type12: {
    icon: iconCould,
    type: "fail-save",
    desc: "載入預覽畫面失敗。",
    notice: "請確認網路連線後再試一次。",
  },
  type13: {
    icon: iconAlert,
    type: "file-preview",
    desc: "您有未儲存的變更，是否儲存並預覽專案?",
    notice: "未儲存的變更將遺失且不會顯示於預覽畫面。",
  },
  type14: {
    icon: iconCould,
    type: "fail-save",
    desc: "儲存失敗。",
    notice: "請確認網路連線後再試一次。",
  },
  type15: {
    icon: iconCould,
    type: "fail-save",
    desc: lang.ErrResponse.whileLoading,
    notice: lang.ErrResponse.checkInternet,
  },
};
