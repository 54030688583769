const THEME = {
  zIndex: {
    LayerOne: 100,
    LayerTwo: 200,
    LayerThree: 300,
    LayerFour: 400,
    LayerFive: 500,
    LayerSix: 600,
    LayerSeven: 700,
    LayerEight: 800,
    LayerNine: 900,
    LayerTen: 1000,
  },
};

export default THEME;
