import React from "react";

import { StyledFailPage } from "./StyledFailPage";

//env
import pageUrl from "constants/pageUrl";

//img
import fail from "./images/fail.svg";

// i18n
import { useTranslation } from "react-i18next";

const FailPage = () => {
  const { t } = useTranslation();

  return (
    <StyledFailPage>
      <div className="failpage_container">
        <img src={fail} className="fail" />
        <h1>{t("FailPage.pageNotFound")}</h1>
        <div className="failWord">{t("FailPage.pageMightDelete")}</div>
        <a className="link" href={pageUrl.AR_BILLING} target="_blank">
          {t("common.learnMoreBtn")}
        </a>
      </div>
    </StyledFailPage>
  );
};

export default FailPage;
