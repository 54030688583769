// api
import axioApi from "api/root.api";

export const getProductInfo = (uid) => async () => {
  try {
    const guestId = sessionStorage.getItem("guest");
    const urlEnd = guestId
      ? `/publish/v1/product/object/${uid}?guest=${guestId}`
      : `/publish/v1/product/object/${uid}`;
    const response = await axioApi.get(urlEnd);
    sessionStorage.setItem("guest", response.headers.guest);
    const result = response.data;
    return result;
  } catch (error) {
    console.log(error);
    return {
      result: false,
      status: "failed",
    };
  }
};
