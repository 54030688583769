// react
import { useEffect, useMemo, useState } from "react";

// styled components
import { ThemeProvider } from "styled-components";
import { StyledApp } from "./StyledApp";

// components
import ModelViewer from "components/ModelViewer/ModelViewer";
import Tutorial from "components/Tutorial/Tutorial";
import ForVRmaker from "components/ForVRmaker/ForVRmaker";
import SideBarNav from "components/SideBarNav/SideBarNav";
import ModalPopup from "components/ModalPopup/ModalPopup";
import FailPage from "components/FailPage/FailPage";
import MobilePopup from "components/MobilePopup/MobilePopup";
import CookieBanner from "components/CookieBanner/CookieBanner";

// constants
import THEME from "constants/theme";

// hooks
import useModal from "hooks/useModal";

// config
import { modalConfig } from "config/modal";

// api
import { getProductInfo } from "api/product/product.api";

// react query
import { useQuery } from "react-query";

// plugin
import Cookies from "js-cookie";
import * as dayjs from "dayjs";

// ga
import ReactGA from "react-ga4";

// i18n
import { useTranslation } from "react-i18next";

// constants
import SERVER from "constants/server";
import ThreeJs from "../../components/ThreeJs/ThreeJs";

ReactGA.initialize("G-B17J6K0VSN");
ReactGA.send("pageview");

const App = () => {
  const { t, i18n } = useTranslation();
  const { modalSetting, setModalSetting } = useModal();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uid = decodeURIComponent(urlParams.get("uid"));
  Cookies.set("uid", uid, { domain: SERVER.GET_DOMAIN, sameSite: "none", secure: true });
  const {
    data: resProductInfo,
    isLoading,
    error,
  } = useQuery("getProductInfo", getProductInfo(uid));

  const [productInfo, setProductInfo] = useState([]);
  const [loadingStatus, setloadingStatus] = useState(true);
  const [err, setErr] = useState(false);

  const [modelViewrIndex, setIndex] = useState(0);
  const [isAlphaBg, setAlphanBg] = useState(false);
  const [isCtrl, setCtrl] = useState(false);
  const [autoRotate, setAutoRotate] = useState(false);
  const [wiggle, setWiggle] = useState(true);

  const [pan, setPan] = useState(true);
  const [rotationPerSecond, setRotationPerSecond] = useState("11.25deg");

  const [isGlobalSwitch, setIsGlobalSwitch] = useState(false);
  const [showBar, setShowBar] = useState(false);

  // 為了vr-maker 客製化
  const [forVRmaker, setForVRmaker] = useState(false);
  const [hiddenModel, setHiddenModel] = useState(false);
  const [hiddenAnimation, setHiddenAnimation] = useState(false);

  const [currentModelFileType, setCurrentModelFileType] = useState(null);
  const [currentModel, setCurrentModel] = useState(null);

  // default parameters
  useEffect(() => {
    if (urlParams.get("lang")) {
      i18n.changeLanguage(urlParams.get("lang"));
    } else if (Cookies.get("lang")) {
      i18n.changeLanguage(Cookies.get("lang"));
    }

    if (urlParams.get("modelIndex")) {
      setIndex(parseFloat(urlParams.get("modelIndex")));
    }

    if (urlParams.get("disableBackground") === "true") {
      setAlphanBg(true);
      setCtrl(true);
    } else if (urlParams.get("disableBackground")) {
      setCtrl(true);
    }

    if (urlParams.get("autoRotate")) {
      setAutoRotate(urlParams.get("autoRotate") === "true");
    }

    if (urlParams.get("wiggle")) {
      setWiggle(urlParams.get("wiggle") === "true");
    }

    if (urlParams.get("pan")) {
      setPan(urlParams.get("pan") === "true");
    }

    if (urlParams.get("rotationPerSecond")) {
      setRotationPerSecond(urlParams.get("rotationPerSecond"));
    }

    // 為了vr-maker 客製化
    if (urlParams.get("onlyForVRmaker")) {
      setForVRmaker(urlParams.get("onlyForVRmaker"));
      setShowBar(false);
      setHiddenAnimation(true);
    }
  }, []);

  // 判斷是否正常連線，再判斷是否有這筆資料
  useEffect(() => {
    let netststus = navigator.onLine;
    if (netststus) {
      if (!resProductInfo?.result && resProductInfo?.status === "failed") {
        // QRCode失效(找不到該頁面)
        setErr(true);
        return;
      }
      if (resProductInfo) {
        setIsGlobalSwitch(resProductInfo.onlineProduct.onlineProductEditors[0].globalSwitch);
        let sortAry = resProductInfo.onlineProduct.onlineProductEditors[0].onlineProductModels.sort(
          (a, b) => {
            return dayjs(b.lastUpdated).unix() - dayjs(a.lastUpdated).unix();
          },
        );

        for (let i = 0; i < sortAry.length; i++) {
          if (sortAry[i].mainObject === true) {
            let mainTemp = sortAry[i];
            sortAry[i] = sortAry[0];
            sortAry[0] = mainTemp;
          }
        }
        setProductInfo(sortAry);
      }
    } else {
      // 網路連線有問題，再試一次
      setModalSetting({
        ...modalSetting,
        show: true,
        title: "",
        type: "type15",
        coverSetting: true,
        handleOtherAction: true,
        otherBtnText: t("ErrResponse.PleaseTryAgain"),
        handleConfirm: () => {
          window.location.reload();
        },
      });
    }
  }, [resProductInfo]);

  const [isAr, setIsAr] = useState(false);
  const [noUsdz, setNoUsdz] = useState(false);
  const [mobilePopup, setMobilePopup] = useState("");

  useEffect(() => {
    setCurrentModel(productInfo[modelViewrIndex]);
    const fileType = productInfo[modelViewrIndex]?.lastUploadFileType;
    if (fileType) {
      setCurrentModelFileType(fileType);
    }
    // 判斷是否開啟AR投放開關，有則顯示AR按鈕 (viewModeSwitch:null/true/false)
    setIsAr(productInfo[modelViewrIndex]?.viewModeSwitch !== false);
    setNoUsdz(productInfo[modelViewrIndex]?.usdzName === "");

    // 判斷是否使用AR投放，有則開始偵測裝置
    if (urlParams.get("openAr")) {
      setMobilePopup("偵測裝置中");

      if (productInfo.length > 0) {
        // 偵測行動裝置
        const mobileDevice = [
          "Android",
          "webOS",
          "iPhone",
          "iPad",
          "iPod",
          "BlackBerry",
          "Windows Phone",
        ];
        let isMobileDevice = mobileDevice.some((e) => navigator.userAgent.match(e));
        // 偵測作業系統
        let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        // 偵測瀏覽器
        let isChrome = navigator.userAgent.indexOf("CriOS") > -1;
        let isSafari = navigator.userAgent.indexOf("Safari") > -1;
        if (isChrome && isSafari) isSafari = false;

        // 判斷使用者是否使用手機裝置
        if (isMobileDevice) {
          // 判斷使用者是否開啟AR投放開關
          if (productInfo[modelViewrIndex]?.viewModeSwitch !== false) {
            // 判斷使用者是否使用IOS作業系統
            if (isIOS) {
              // 判斷物件是否缺少USDZ檔案
              if (productInfo[modelViewrIndex]?.usdzName === "") {
                // 缺少USDZ檔案，則無法開啟AR投放
                setMobilePopup("無法使用");
              } else {
                // 判斷使用者是否使用Safari瀏覽器
                if (isSafari) {
                  const getMovelViewer = document.querySelector("model-viewer");
                  getMovelViewer.addEventListener("load", () => {
                    setMobilePopup("偵測裝置成功");
                    getMovelViewer.activateAR();
                  });
                } else {
                  // 使用非Safari瀏覽器，則無法開啟AR投放
                  setMobilePopup("未使用Safari瀏覽器");
                }
              }
            } else {
              // 其餘系統不需判斷檔案，直接開啟AR投放
              const getMovelViewer = document.querySelector("model-viewer");
              getMovelViewer.addEventListener("load", () => {
                setMobilePopup("偵測裝置成功");
                getMovelViewer.activateAR();
              });
            }
          } else {
            // 未開啟AR投放開關
            setMobilePopup("無法使用");
          }
        } else {
          // AR投放無法在目前的作業系統使用
          setMobilePopup("無法使用");
        }
      }
    }
  }, [productInfo, modelViewrIndex]);

  const isGSModel = useMemo(() => currentModelFileType?.includes("splat"), [currentModelFileType]);

  return (
    <ThemeProvider theme={THEME}>
      <StyledApp isAlphaBg={isAlphaBg} forVRmaker={forVRmaker}>
        {mobilePopup && <MobilePopup type={mobilePopup} modelIndex={modelViewrIndex} />}
        {err ? (
          <FailPage />
        ) : isGSModel ? (
          <ThreeJs setloadingStatus={setloadingStatus} currentModel={currentModel} />
        ) : (
          <ModelViewer
            modelList={productInfo}
            modelIndex={modelViewrIndex}
            setloadingStatus={setloadingStatus}
            loadingStatus={loadingStatus}
            autoRotate={autoRotate}
            wiggle={wiggle}
            pan={pan}
            rotationPerSecond={rotationPerSecond}
            showBar={showBar}
            setShowBar={setShowBar}
            hiddenModel={hiddenModel}
            hiddenAnimation={hiddenAnimation}
          />
        )}
        {!isGSModel && !isCtrl && isGlobalSwitch && (
          <SideBarNav
            modelList={productInfo}
            modelIndex={modelViewrIndex}
            setIndex={setIndex}
            loadingStatus={loadingStatus}
            setloadingStatus={setloadingStatus}
            showBar={showBar}
            setShowBar={setShowBar}
          />
        )}
        {!isGSModel && !isCtrl && (
          <Tutorial
            isAr={isAr}
            modelIndex={modelViewrIndex}
            loadingStatus={loadingStatus}
            productInfo={productInfo}
            noUsdz={noUsdz}
          />
        )}
        {forVRmaker && (
          <ForVRmaker
            modelIndex={modelViewrIndex}
            setMobilePopup={setMobilePopup}
            noUsdz={noUsdz}
            setHiddenModel={setHiddenModel}
          />
        )}
        {modalSetting.show && (
          <ModalPopup
            modalConfig={modalConfig[modalSetting.type]}
            setModalSetting={setModalSetting}
            modalSetting={modalSetting}
          />
        )}
        <CookieBanner />
      </StyledApp>
    </ThemeProvider>
  );
};

export default App;
