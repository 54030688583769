// react
import { useEffect, useState, useRef, useMemo } from "react";

// styled components
import { StyledModelViewer } from "./StyledModelViewer";
import { ReactComponent as UrlIcon } from "./url-icon.svg";

// hdr file
import HDR_FILE from "constants/hrd";

// react bootstrap
import ProgressBar from "react-bootstrap/ProgressBar";

// plugin
import clsx from "classnames";
import "@google/model-viewer/dist/model-viewer";
import Cookies from "js-cookie";

// utils
import {
  disableHandleCameraMovement,
  enableHandleCameraMovement,
  handleCameraMovement,
} from "utils/customFunctions";

// react icon
import { BsPlus } from "react-icons/bs";
import { AiOutlineClose, AiOutlineLink } from "react-icons/ai";

// config
import { modalConfig } from "config/modal";

// hooks
import useModal from "hooks/useModal";

// image
import arImg from "./images/ar.svg";
import ModalPopup from "components/ModalPopup/ModalPopup";
import check from "./images/check.svg";
import namIcon from "./images/namIcon.svg";
import tagIcon from "./images/tagIcon.svg";

// i18n
import { useTranslation } from "react-i18next";

const ModelViewer = (props) => {
  const { t } = useTranslation();
  const myViewer = useRef();
  const { modalSetting, setModalSetting } = useModal();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [hotspots, setHotspots] = useState([]);
  const [currentHotspot, setCurrentHotspot] = useState(null);
  const [sectionType, setSectionType] = useState("");
  const [xSectionType, setXSectionType] = useState("");
  const [modelLoadingProgress, setModelLoadingProgress] = useState(0);
  const [loadingBackground, setLoadingBackground] = useState("transparent");
  const [hdrSky, setHdrSky] = useState("");
  const [interactionPromptStyle, setInteractionPromptStyle] = useState("wiggle");
  const [modelDragged, setModelDragged] = useState(false);

  const onlyTitle = !currentHotspot?.content && !currentHotspot?.icon && !currentHotspot?.url;

  const fullCard =
    (currentHotspot?.title && currentHotspot?.content && currentHotspot?.icon) ||
    currentHotspot?.url;

  const onlyTitleDesc =
    currentHotspot?.title &&
    currentHotspot?.content &&
    !currentHotspot?.icon &&
    !currentHotspot?.url;

  const onlyTitleImage =
    currentHotspot?.title &&
    !currentHotspot?.content &&
    currentHotspot?.icon &&
    !currentHotspot?.url;

  const decOver36 = currentHotspot?.content.length >= 36;

  useEffect(() => {
    handleCameraMovement();
  }, []);

  useEffect(() => {
    if (props.autoRotate) {
      document.querySelector("#myViewer").setAttribute("auto-rotate", true);
    } else {
      document.querySelector("#myViewer").removeAttribute("auto-rotate");
    }
  }, [props.autoRotate]);

  useEffect(() => {
    if (props.wiggle) {
      setInteractionPromptStyle("wiggle");
    } else {
      setInteractionPromptStyle("basic");
    }
  }, [props.wiggle]);

  useEffect(() => {
    // 每次渲染都要執行，不可縮放，限定視角
    if (urlParams.get("zoom") === "true") {
      document.querySelector("#myViewer").setAttribute("disable-zoom", true);
    }
    if (urlParams.get("pitchLimits") === "true") {
      document.querySelector("#myViewer").setAttribute("min-camera-orbit", "auto 0deg auto");
      document.querySelector("#myViewer").setAttribute("max-camera-orbit", "auto 90deg auto");
    }
  });

  useEffect(() => {
    if (props.pan) {
      enableHandleCameraMovement();
    } else {
      disableHandleCameraMovement();
    }
  }, [props.pan]);

  useEffect(() => {
    if (props.modelList.length > 0) {
      const getMovelViewer = document.querySelector("#myViewer");
      getMovelViewer.addEventListener("progress", (e) => {
        const getProgress = e.detail.totalProgress;
        setModelLoadingProgress(+getProgress.toFixed(2));
        setTimeout(() => {
          if (getProgress === 1) {
            props.setloadingStatus(false);
          }
        }, 500);
      });
      // getMovelViewer.addEventListener('error', (e) => {
      //     setModalSetting({
      //         ...modalSetting,
      //         show: true,
      //         title: '',
      //         type: 'type15',
      //         coverSetting: true,
      //         handleOtherAction: true,
      //         otherBtnText: t('ErrResponse.PleaseTryAgain'),
      //         handleConfirm: () => {
      //             window.location.reload();
      //         },
      //     });
      // });
    }
  }, [props.modelList]);

  useEffect(() => {
    const isIframe = window.self !== window.top;
    const disableBackground =
      new URLSearchParams(window.location.search).get("disableBackground") === "true";
    if (isIframe && disableBackground) setLoadingBackground("transparent");
    else setLoadingBackground("rgba(255, 255, 255, 1)");
  });

  useEffect(() => {
    setHotspots([]);
    if (props.modelList[props.modelIndex]?.tagSwitch === true) {
      setHotspots(props.modelList[props.modelIndex]?.onlineProductTags);
    }
    setHdrSky("");
    if (props.modelList[props.modelIndex]?.environment === "neutral") {
      setHdrSky("neutral");
    } else if (props.modelList[props.modelIndex]?.environment === "default") {
      setHdrSky("");
    } else if (props.modelList[props.modelIndex]?.environment === "spruit sunrise") {
      setHdrSky(HDR_FILE.SPRUIR_SUNRISE);
    } else if (props.modelList[props.modelIndex]?.environment === "aircraft workshop") {
      setHdrSky(HDR_FILE.AIRCRAFT_WORKSHOP);
    } else if (props.modelList[props.modelIndex]?.environment === "music hall") {
      setHdrSky(HDR_FILE.MUSIC_HALL);
    } else if (props.modelList[props.modelIndex]?.environment === "pillars") {
      setHdrSky(HDR_FILE.PILLARS);
    } else if (props.modelList[props.modelIndex]?.environment === "whipple creek") {
      setHdrSky(HDR_FILE.WHIPPLE_CREEK);
    }
  }, [props.modelIndex, props.modelList]);

  const handleHideInfoCard = () => {
    if (modelDragged === true) {
      setModelDragged(false);
      return;
    }

    document.querySelector("#myViewer").removeAttribute("auto-rotate");
    document.querySelector("#interaction-prompt").setAttribute("slot", "interaction-prompt");
    setInteractionPromptStyle("basic");
    setCurrentHotspot(null);
  };

  const handleMouseMove = (MouseEvent) => {
    if (MouseEvent.buttons % 2 === 1) {
      if (modelDragged === false) {
        setModelDragged(true);
      }
    }
  };

  const handleDoubleClick = () => handleHideInfoCard();

  const handleClickHotspot = (tag, spotId) => (e) => {
    if (tag.id === currentHotspot?.id) {
      setCurrentHotspot(null);
      return;
    }

    document.querySelector("#myViewer").removeAttribute("auto-rotate");
    document.querySelector("#interaction-prompt").setAttribute("slot", "interaction-prompt");
    setInteractionPromptStyle("basic");

    const modelViewer = document.querySelector("#myViewer");
    modelViewer.cameraTarget = tag.cameraTarget;
    modelViewer.cameraOrbit = tag.cameraOrbit;

    e.stopPropagation();
    const getSpot = document.querySelector(`#${spotId}`).getBoundingClientRect();
    const halfW = window.innerWidth / 2;
    const halfH = window.innerHeight / 2;
    const spotX = getSpot.x;
    const spotY = getSpot.y;
    const getSectionType = () => {
      if (spotX > halfW && spotY > halfH) return "section4";
      if (spotX > halfW && spotY < halfH) return "section2";
      if (spotX < halfW && spotY > halfH) return "section1";
      return "section3";
    };

    const getXSectionType = () => {
      if (spotX < halfW) return "xSection1";
      return "xSection2";
    };

    setCurrentHotspot(tag);
    setSectionType(getSectionType());

    if (!fullCard) {
      setXSectionType(getXSectionType());
    }
    return;
  };

  const [fullMobile, setFullMobile] = useState(false);
  const [hotInfo, setHontInfo] = useState({
    title: "",
    content: "",
    img: "",
    url: "",
  });

  const showFullHot = (spot, index) => () => {
    setFullMobile(true);
    // console.log(fullMobile);
    setHontInfo({
      title: spot.title,
      content: spot.content,
      img: spot.icon,
      url: spot.url,
    });
  };

  const closeMobileHot = () => {
    setFullMobile(false);
    setCurrentHotspot(null);
  };

  // ======================= animation ========================
  const [animationList, setAnimationList] = useState(null);
  const [currentAnimate, setCurrentAnimate] = useState(null);
  const [mainAnimateSwitch, setMainAnimateSwitch] = useState(null);

  const [animatePlayStatus, setAnimatePlayStatus] = useState(false);
  const [showAnimateHidden, setShowAnimateHidden] = useState(false);
  const [currentPlayTime, setCurrentPlayTime] = useState("0.00");
  const [currentPercent, setcurrentPercent] = useState(0);
  const [showTootltip, setShowTootltip] = useState(null);
  const [tootltipItemName, setTootltipItemName] = useState(null);
  const [tootltipItemTop, setTootltipItemTop] = useState(0);
  const [tootltipTime, setTootltipTime] = useState(null);
  const [showTootltipTime, setShowTootltipTime] = useState(false);
  const [tootltipTimePositionX, setTootltipTimePositionX] = useState(0);
  const [animationIsReady, setAnimationIsReady] = useState(false);
  const [isAfterScrub, setIsAfterScrub] = useState(false);
  const [isOpenTag, setIsOpenTag] = useState(true);

  useEffect(() => {
    setIsOpenTag(true);
    const getModelViewer = document.querySelector("#myViewer");
    getModelViewer.removeAttribute("animation-name");
    if (props.modelList?.length > 0) {
      let mainModel = props.modelList[props.modelIndex];
      setMainAnimateSwitch(mainModel.annotationSwitch);
      // 初始獲得動畫資料
      let List = [];
      getModelViewer.addEventListener("load", async (e) => {
        for (let item of getModelViewer.availableAnimations) {
          await getModelViewer.setAttribute("animation-name", item);
          List.push({
            name: item,
            duration: getModelViewer.duration.toFixed(2),
            ownSwitch: true,
            disable: false,
          });
        }
        if (List.length === 0) {
          setAnimationList(null);
          setCurrentAnimate(null);
          return;
        }
        // 判斷有無設定
        if (JSON.parse(mainModel.animationItemSwitch)) {
          for (let i = 0; i < Object.keys(JSON.parse(mainModel.animationItemSwitch)).length; i++) {
            List[i].ownSwitch = JSON.parse(mainModel.animationItemSwitch)[i].ownSwitch;
          }
        }
        let defaultCurrentAnimate = List.filter((item) => {
          return item.ownSwitch;
        });
        setAnimationList(List);
        setCurrentAnimate(defaultCurrentAnimate[0]);
        List = [];
        if (mainModel.onlineProductTags.length === 0 && mainModel.annotationSwitch) {
          await getModelViewer.setAttribute("animation-name", defaultCurrentAnimate[0].name);
          setIsOpenTag(false);
          // 為了vr-make r 客製化
          if (urlParams.get("onlyForVRmaker")) {
            setIsOpenTag(true);
          }
        }
        setAnimationIsReady(true);
      });
    }
  }, [props.modelIndex, props.modelList]);

  useEffect(() => {
    if (animationIsReady && mainAnimateSwitch && !isOpenTag) {
      const getModelViewer = document.querySelector("#myViewer");
      getModelViewer.play();
      setAnimatePlayStatus(true);
    }
    setAnimationIsReady(false);
  }, [animationIsReady]);

  const handleChangeAnimate = async (item) => {
    const getModelViewer = document.querySelector("#myViewer");
    getModelViewer.pause();
    await getModelViewer.setAttribute("animation-name", item.name);
    setCurrentAnimate(item);
    if (animatePlayStatus) {
      getModelViewer.play();
      setAnimatePlayStatus(true);
    }
  };

  useEffect(() => {
    const getModelViewer = document.querySelector("#myViewer");
    if (animatePlayStatus) {
      var interval = setInterval(() => {
        setCurrentPlayTime(getModelViewer.currentTime.toFixed(2));
        let percent = (getModelViewer.currentTime / getModelViewer.duration) * 100;
        setcurrentPercent(percent);
      }, 1);
    }
    return () => {
      clearInterval(interval);
    };
  }, [animatePlayStatus]);

  useEffect(() => {
    setCurrentPlayTime("0.00");
    setcurrentPercent(0);
  }, [currentAnimate]);

  useEffect(() => {
    if (isAfterScrub && animatePlayStatus) {
      var mytimmer = setTimeout(() => {
        const getModelViewer = document.querySelector("#myViewer");
        getModelViewer.play();
        setAnimatePlayStatus(true);
      }, 100);
    }
    return () => {
      clearTimeout(mytimmer);
    };
  }, [isAfterScrub]);

  const handlePlayStatus = () => {
    const getModelViewer = document.querySelector("#myViewer");
    if (animatePlayStatus) {
      getModelViewer.pause();
    } else {
      getModelViewer.play();
    }
    setAnimatePlayStatus(!animatePlayStatus);
  };

  const onScrub = (percent) => {
    setIsAfterScrub(true);
    var windowWidth = window.innerWidth;
    const getModelViewer = document.querySelector("#myViewer");
    getModelViewer.pause();
    if (windowWidth < 768) {
      const touch = document.getElementById("playerInput");
      getModelViewer.currentTime = (percent * getModelViewer.duration) / 100;
      setcurrentPercent(percent);
      setCurrentPlayTime(((percent * getModelViewer.duration) / 100).toFixed(2));
      const handleTouchend = (PlayStatus) => {
        if (PlayStatus) {
          getModelViewer.play();
        }
      };
      touch.addEventListener("touchend", handleTouchend(animatePlayStatus));
    } else {
      let value = (tootltipTime * 100) / getModelViewer.duration;
      getModelViewer.currentTime = tootltipTime;
      setcurrentPercent(value);
      setCurrentPlayTime(tootltipTime);
    }
  };

  const handleAfterScrub = () => {
    setIsAfterScrub(false);
    if (animatePlayStatus && tootltipTime == 0.0) {
      const getModelViewer = document.querySelector("#myViewer");
      getModelViewer.play();
    }
  };

  const handleHoverTooltips = (name, e) => {
    // 比對的是英文或數字
    var regExp = /[a-zA-Z0-9]/;
    if (name.length < 15 && regExp.test(name)) {
      return;
    } else if (name.length < 7 && !regExp.test(name)) {
      return;
    }
    setTootltipItemName(name);
    let Y = e.target.getBoundingClientRect().bottom;
    setTootltipItemTop(Y - 10);
    setShowTootltip(true);
  };

  const handleshowTooltipTime = (e) => {
    const getModelViewer = document.querySelector("#myViewer");
    let event = window.event;
    let inputidth = e.target.clientWidth;
    let X = e.target.getBoundingClientRect().left;
    let mouseX = event.pageX;
    let nowMouseTootipTime = (((mouseX - X) / inputidth) * getModelViewer.duration).toFixed(2);
    if (nowMouseTootipTime < 0 || nowMouseTootipTime > getModelViewer.duration.toFixed(2)) {
      setShowTootltipTime(false);
      return;
    }
    if (mouseX - X < 0) {
      nowMouseTootipTime = 0.001;
      nowMouseTootipTime = nowMouseTootipTime.toFixed(2);
    }
    setTootltipTime(nowMouseTootipTime);
    setShowTootltipTime(true);
    setTootltipTimePositionX(mouseX - X + 10);
  };

  const handleChangeMode = async (e) => {
    const getModelViewer = document.querySelector("#myViewer");
    if (isOpenTag) {
      // tag轉animation
      let defaultCurrent = animationList.find((item) => item.ownSwitch === true);
      setCurrentAnimate(defaultCurrent);
      getModelViewer.setAttribute("animation-name", defaultCurrent.name);
      setcurrentPercent(0);
      setCurrentPlayTime("0.00");
      getModelViewer.currentTime = 0;
      getModelViewer.play();
      setAnimatePlayStatus(true);
    } else {
      getModelViewer.pause();
      getModelViewer.setAttribute("animation-name", "");
      setAnimatePlayStatus(false);
    }
    setIsOpenTag(!isOpenTag);
  };

  const hideiStagingTextParam = new URLSearchParams(window.location.search).get("hideiStagingText");
  const hideiStagingTextStorage = sessionStorage.getItem("hideiStagingText");
  const userId = Cookies.get("uid");

  useEffect(() => {
    if (hideiStagingTextParam === "true") {
      sessionStorage.setItem("hideiStagingText", "true");
    }
  }, [hideiStagingTextParam]);

  const isHideIStagingText = useMemo(() => {
    return (
      hideiStagingTextParam === "true" || sessionStorage.getItem("hideiStagingText") === "true"
    );
  }, [hideiStagingTextParam, hideiStagingTextStorage]);

  const shouldShowProgressText = useMemo(() => {
    const hiddenUserIds = [
      "229285e6-6394-422e-aaea-3074d9ae6a31",
      "ef648969-7a21-41ea-a63d-f57bb9133d11",
    ];
    return !isHideIStagingText && !hiddenUserIds.includes(userId);
  }, [hideiStagingTextParam, userId]);

  return (
    <StyledModelViewer
      onlyTitle={onlyTitle}
      onlyTitleDesc={onlyTitleDesc}
      onlyTitleImage={onlyTitleImage}
      fullCard={fullCard}
      decOver36={decOver36}
      animatePlayStatus={animatePlayStatus}
      showAnimateHidden={showAnimateHidden}
      isOpenTag={isOpenTag}
      showBar={props.showBar}
      showTootltip={showTootltip}
      hiddenModel={props.hiddenModel}
    >
      {urlParams.get("openAr") && (
        // 單純蓋住啟用AR投放時，避免 progress bar 或其他元件出現在畫面中
        <div className="justForArLoading"></div>
      )}
      {modelLoadingProgress < 1 && (
        <div
          className="loadingProgressContainer flexCenter flexY"
          style={{ background: loadingBackground }}
        >
          {shouldShowProgressText && (
            // 暫時不露出廠商 Logo
            <div className="progressText">iStaging</div>
          )}
          <div className="progressBarPanel">
            <ProgressBar now={modelLoadingProgress} min={0} max={1} />
          </div>
        </div>
      )}
      {fullMobile && (
        <div className="openMobileInner">
          <span className="closeBtn" onClick={closeMobileHot}>
            <AiOutlineClose />
          </span>
          <div className="title">{hotInfo.title}</div>
          <div className="word">{hotInfo.content}</div>
          {hotInfo.img === "" ? "" : <img className="mobileImg" src={hotInfo.img} />}
          {hotInfo.url === "" ? (
            ""
          ) : (
            <a className="mobileLink" href={hotInfo.url} target="_blank">
              <AiOutlineLink />
              {hotInfo.url}
            </a>
          )}
        </div>
      )}
      <model-viewer
        ar
        interaction-prompt-style={interactionPromptStyle}
        camera-controls
        ref={myViewer}
        id="myViewer"
        // src="./assets/ball/scene.gltf"
        src={`${props.modelList[props.modelIndex]?.lastUploadFileType === "gltf" ? props.modelList[props.modelIndex]?.gltfDnsSrc : props.modelList[props.modelIndex]?.glbDnsSrc}`}
        ios-src={props.modelList[props.modelIndex]?.usdzDnsSrc}
        poster=""
        ar-modes="webxr scene-viewer quick-look"
        seamless-poster=""
        loading="auto"
        rotation-per-second={props.rotationPerSecond}
        onDoubleClick={handleDoubleClick}
        onClick={handleHideInfoCard}
        onMouseMove={handleMouseMove}
        shadow-intensity={props.modelList[props.modelIndex]?.shadowIntensity}
        shadow-softness={props.modelList[props.modelIndex]?.shadowSoftness}
        exposure={props.modelList[props.modelIndex]?.exposure}
        environment-image={hdrSky}
        interaction-prompt-style={!isOpenTag ? "basic" : "wiggle"}
        style={{ zIndex: 9, opacity: modelLoadingProgress === 1 ? "1" : "0" }}
      >
        <div id="interaction-prompt"></div>
        {!isOpenTag && <div slot="interaction-prompt"></div>}
        <button id="modelArButton" slot="ar-button" style={{ display: "none" }}>
          <img src={arImg} alt="" />
        </button>
        {isOpenTag &&
          hotspots?.map((spot, index) => {
            return (
              <button
                className="hotspotsButton"
                key={spot.id}
                className="hotspot"
                id={`hotspot-${index}`}
                name={`hotspot-${index}`}
                slot={`hotspot-${index}`}
                data-tag-id={spot.id}
                data-position={spot.dataPosition}
                data-normal={spot.dataNormal}
                data-orbit={spot.cameraOrbit}
                data-target={spot.cameraTarget}
                onClick={handleClickHotspot(spot, `hotspot-${index}`)}
              >
                {index + 1}
                <div
                  className={clsx("infoCard", {
                    show: spot.id === currentHotspot?.id,
                    [sectionType]: fullCard,
                    [xSectionType]: !fullCard,
                  })}
                  onClick={(e) => e.stopPropagation()}
                  onMouseEnter={(e) => e.stopPropagation()}
                  onMouseLeave={(e) => e.stopPropagation()}
                  onMouseMove={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                >
                  <div className="title flexStartCenter">{currentHotspot?.title}</div>
                  {currentHotspot?.content && (
                    <div className="content">{currentHotspot?.content}</div>
                  )}
                  {currentHotspot?.icon && (
                    <div className="image">
                      <img src={currentHotspot?.icon} alt="hotspot img" />
                    </div>
                  )}
                  {currentHotspot?.url && (
                    <a
                      className="urlPanel flexCenter"
                      href={currentHotspot?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UrlIcon className="urlIcon" />
                      <div className="url flexStartCenter">
                        <span>{currentHotspot?.url}</span>
                      </div>
                    </a>
                  )}
                  <div className="more" onClick={showFullHot(spot, index)}>
                    {t("common.seeMoreBtn")} <BsPlus />
                  </div>
                </div>
              </button>
            );
          })}
      </model-viewer>
      {!props.hiddenAnimation && !isOpenTag && mainAnimateSwitch && animationList?.length > 0 && (
        <div className="animation_conainer">
          {animationList && (
            <div
              className="animation_hidden_mobile_list"
              onMouseLeave={() => {
                setShowAnimateHidden(false);
              }}
            >
              <div className="animation_hidden_mobile_list_controller">
                <div
                  className="icon"
                  onClick={() => {
                    handlePlayStatus();
                  }}
                ></div>
                <div
                  className="close"
                  onClick={() => {
                    setShowAnimateHidden(false);
                  }}
                ></div>
              </div>
              <div className="animation_hidden_mobile_list_itemContainer">
                {animationList.map((item) => {
                  return (
                    item.ownSwitch && (
                      <div
                        className={clsx("animation_hidden_mobile_list_itemContainer_item", {
                          currentPlay: item.name === currentAnimate?.name,
                        })}
                        key={item.name}
                        onClick={() => {
                          handleChangeAnimate(item);
                        }}
                      >
                        <div className="name">{item.name}</div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          )}
          <div className="animation_bar">
            <input
              id="playerInput"
              type="range"
              step="0.01"
              value={currentPercent}
              min="0"
              max="100"
              onMouseMove={(e) => {
                handleshowTooltipTime(e);
              }}
              onClick={() => {
                setCurrentPlayTime(tootltipTime);
              }}
              onMouseLeave={() => {
                setShowTootltipTime(false);
              }}
              onMouseUp={handleAfterScrub}
              onChange={(e) => {
                onScrub(e.target.value);
              }}
              style={{ backgroundSize: `${currentPercent}% 100%` }}
            />
            {showTootltipTime && (
              <div className="animation_bar_tooltip" style={{ left: `${tootltipTimePositionX}px` }}>
                {tootltipTime}
              </div>
            )}
            <div className="animation_bar_duration">{currentPlayTime}</div>
          </div>
          <div className="animation_control">
            <div
              className="animation_control_icon_box"
              onClick={() => {
                handlePlayStatus();
              }}
            >
              <div className="icon"></div>
            </div>
            <div
              className="animation_control_chose"
              onClick={() => {
                setShowAnimateHidden(!showAnimateHidden);
              }}
            >
              <div className="animation_control_chose_icon"></div>
              <div className="animation_control_chose_name">{currentAnimate?.name}</div>
            </div>
            <div className="animation_control_duration">{currentPlayTime}</div>
          </div>
          {animationList && showAnimateHidden && (
            <div
              className="animation_hidden_list"
              onMouseLeave={() => {
                setShowAnimateHidden(false);
              }}
            >
              {animationList.map((item) => {
                return (
                  item.ownSwitch && (
                    <div
                      className={clsx("animation_hidden_list_item", {
                        currentPlay: item.name === currentAnimate?.name,
                      })}
                      key={item.name}
                      onClick={() => {
                        handleChangeAnimate(item);
                      }}
                    >
                      <div className="check">
                        {currentAnimate?.name === item.name && <img src={check} />}
                      </div>
                      <div
                        className="name"
                        onMouseEnter={(e) => {
                          handleHoverTooltips(item.name, e);
                        }}
                        onMouseLeave={() => setShowTootltip(false)}
                      >
                        {item.name}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          )}
        </div>
      )}
      {
        <div className="animation_hidden_list_tooltip" style={{ top: `${tootltipItemTop}px` }}>
          {tootltipItemName}
        </div>
      }
      {!props.hiddenAnimation &&
        hotspots?.length > 0 &&
        mainAnimateSwitch &&
        animationList?.length > 0 && (
          <div className="choseOpenMode" onClick={handleChangeMode}>
            {isOpenTag ? (
              <div className="icon">
                <img src={namIcon} alt="" />
                <span>物件動畫</span>
              </div>
            ) : (
              <div className="icon">
                <img src={tagIcon} alt="" />
                <span>物件標籤</span>
              </div>
            )}
          </div>
        )}
      {modalSetting.show && (
        <ModalPopup
          modalConfig={modalConfig[modalSetting.type]}
          setModalSetting={setModalSetting}
          modalSetting={modalSetting}
        />
      )}
    </StyledModelViewer>
  );
};

export default ModelViewer;
