import { useState, useEffect } from "react";

// styles
import { StyledVRTutorial } from "./StyledVRTutorial";

// i18n
import { useTranslation } from "react-i18next";

const Tutorial = (props) => {
  const { t } = useTranslation();

  // 控制畫面是否為全螢幕
  const [fullBool, setFull] = useState(false);

  useEffect(() => {
    if (fullBool === true) {
      if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullScreen();
      }
    } else {
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }, [fullBool]);

  const fullEvent = () => {
    setFull(!fullBool);
  };

  const openTutorialMode = (mode) => {
    if (props.forVRstate.tutorialMode != null && props.forVRstate.tutorialMode === mode) {
      props.forVRdispatch({ type: "closeTutorialMode" });
    } else {
      props.forVRdispatch({ type: "openTutorialMode", payload: mode });
    }
  };

  const closeARviewer = () => {
    console.log("window.parent.postMessage");
    window.parent.postMessage({ action: "close" }, "*");
    // let closeAR = document.getElementById('closeAR');
    // closeAR.addEventListener('click', () => window.top.postMessage({ action: 'close' }, 'https://livetour.istaging.com'));
  };

  return (
    <StyledVRTutorial forRWDshowTutorial={props.forVRstate.forRWDshowTutorial}>
      {
        <div className="tutorial">
          <div className="tutorial_ARactive" onClick={() => openTutorialMode("ARactive")}>
            <div className="tutorialTooltip">{t("Tutorial.ARProjection")}</div>
          </div>
          <div className="tutorial_Touch" onClick={() => openTutorialMode("tutorial")}>
            <div className="tutorialTooltip">{t("Tutorial.gestureAssistance")}</div>
          </div>
          {fullBool ? (
            <div className="tutorial_CloseFullScreen" onClick={fullEvent}>
              <div className="tutorialTooltip">{t("Tutorial.exitFullScreen")}</div>
            </div>
          ) : (
            <div className="tutorial_OpenFullScreen" onClick={fullEvent}>
              <div className="tutorialTooltip">{t("Tutorial.fullScreen")}</div>
            </div>
          )}
          <div className="tutorial_GoOut" id="closeAR" onClick={closeARviewer}>
            <div className="tutorialTooltip">{t("Tutorial.exit3D")}</div>
          </div>
        </div>
      }
    </StyledVRTutorial>
  );
};

export default Tutorial;
