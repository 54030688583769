import React, { useEffect, useRef } from "react";
import CustomGS3DViewer from "core/three/CustomGS3DViewer";
import Three from "core/three/Three";

const ThreeJs = React.memo(({ setloadingStatus, currentModel }) => {
  const viewerRef = useRef(null);
  const containerRef = useRef(null);
  const initializedRef = useRef(false);

  const onWindowResize = () => {
    if (viewerRef.current && containerRef.current) {
      const { clientWidth: width, clientHeight: height } = containerRef.current;
      const viewer = viewerRef.current;
      viewer.renderer.setSize(width, height);
      viewer.camera.aspect = width / height;
      viewer.camera.updateProjectionMatrix();
    }
  };

  useEffect(() => {
    if (!initializedRef.current) {
      const three = new Three();
      three.init(containerRef.current);
      const viewer = new CustomGS3DViewer({
        renderer: three.renderer,
        cameraUp: [0, 0, 1],
        initialCameraLookAt: [0, 0, 0],
        sharedMemoryForWorkers: false,
      });

      viewerRef.current = viewer;
      viewer
        .addSplatScene(currentModel.splatSrc, {
          splatAlphaRemovalThreshold: 5,
          showLoadingUI: true,
          scale: [5, 5, 5],
        })
        .then(() => {
          viewer.start();
          setloadingStatus(false);
        });

      window.addEventListener("resize", onWindowResize);
      initializedRef.current = true;
    }

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
});

export default ThreeJs;
