import styled from "styled-components";

export const StyledFailPage = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-content: center;

  .failpage_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
    justify-content: center;
    @media all and (max-width: 768px) {
      width: 240px;
    }
  }

  .fail {
    width: 100%;
    max-width: 640px;
    margin-bottom: 16px;
  }
  h1 {
    width: 100%;
    font-weight: 500;
    font-size: 32px;
    color: #333;
    margin-bottom: 8px;
    @media all and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .failWord {
    width: 100%;
    font-size: 16px;
    color: #666;
    @media all and (max-width: 768px) {
      font-size: 12px;
    }
  }
  .link {
    font-size: 16px;
    color: #fff;
    background-color: #ed0973;
    border-radius: 4px;
    padding: 8px 24px;
    line-height: 24px;
    display: block;
    text-decoration: none;
    margin-top: 40px;
    &:hover {
      background-color: #f2539d;
    }
    @media all and (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
