import { useState, useEffect, useReducer, useMemo } from "react";
import { getQueryString } from "utils/url";

// components
import VRTutorial from "../VRTutorial/VRTutorial";
import VRMode from "../VRMode/VRMode";

// 初始化 state
const initialState = { tutorialMode: null, forRWDshowTutorial: true };

function reducer(state, action) {
  switch (action.type) {
    case "openTutorialMode":
      return { tutorialMode: action.payload, forRWDshowTutorial: false };
    case "closeTutorialMode":
      return { tutorialMode: null, forRWDshowTutorial: true };
    default:
      return state;
  }
}

export default function ForVRmaker({ modelIndex, setMobilePopup, noUsdz, setHiddenModel }) {
  const [forVRstate, forVRdispatch] = useReducer(reducer, initialState);

  const [isMobile, setIsMobile] = useState(false);
  const [isQrcode, setQrcode] = useState(false);
  const isVisionPro = useMemo(() => getQueryString("device") === "vision-pro", []);

  const showQrCode = async () => {
    if (isMobile) {
      // 手機版裝置
      setMobilePopup("偵測裝置中");

      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        // 當使用者使用ios系統開啟，判斷物件是否缺少 USDZ
        if (noUsdz) {
          setMobilePopup("無法使用");
        } else {
          setMobilePopup("偵測裝置成功");
          const getMovelViewer = document.querySelector("#myViewer");
          await getMovelViewer.activateAR();
        }
      } else {
        // 其餘系統不需判斷檔案，直接開啟AR投放
        setMobilePopup("偵測裝置成功");
        const getMovelViewer = document.querySelector("#myViewer");
        await getMovelViewer.activateAR();
      }
    } else {
      // 電腦版裝置
      setHiddenModel(true);
      setQrcode(true);
    }
  };

  useEffect(() => {
    const mobileDevice = [
      "Android",
      "webOS",
      "iPhone",
      "iPad",
      "iPod",
      "BlackBerry",
      "Windows Phone",
    ];
    let isMobileDevice = mobileDevice.some((e) => navigator.userAgent.match(e));
    setIsMobile(isMobileDevice);
  }, [navigator.userAgent]);

  useEffect(() => {
    setHiddenModel(false);
    if (forVRstate.tutorialMode === "tutorial") {
      setHiddenModel(true);
    }
    if (forVRstate.tutorialMode === "ARactive") {
      showQrCode();
    }
  }, [forVRstate]);
  return (
    <div>
      {!isVisionPro && <VRTutorial forVRstate={forVRstate} forVRdispatch={forVRdispatch} />}
      <VRMode
        modelIndex={modelIndex}
        isQrcode={isQrcode}
        forVRstate={forVRstate}
        forVRdispatch={forVRdispatch}
      />
    </div>
  );
}
