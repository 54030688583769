import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "pages/App/App";

// redux
import { Provider } from "react-redux";
import store from "./redux/store";

// react query
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";

import "bootstrap/dist/css/bootstrap.min.css";

// i18n
import "./i18n";

const queryCache = new QueryCache();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient} contextSharing={true} queryCache={queryCache}>
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root"),
);
